<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        TabItem,
        Tabs,
        Textarea,
        Modal,
        Select,
        Dropdown,
        DropdownItem,
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { fetchFiles, ask_llm, download_file, deleteAllFiles } from "../service/http_calls";
    import {
        files_list,
        research_add_instructions_modal_open,
        research_baseline_user_instructions,
        generated_content,
        research_page_selected_tab,
        selected_model,
        current_user,
        research_initial,
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import ResponseComponent from "./ResponseComponent.svelte";
    import { toast } from "@zerodevx/svelte-toast";
    import { marked } from "marked";
    import {
        FileCopyOutline,
        TrashBinOutline,
        ChevronDownOutline,
    } from "flowbite-svelte-icons";

    let downloading_file = false;
    let clearResponsesModal = false;

    let all_models = [
        { name: "gemini-1.5-flash", value: "gemini-1.5-flash" },
        { name: "gemini-1.5-pro", value: "gemini-1.5-pro" },
        { name: "perplexity", value: "perplexity" },
        { name: "gpt-4o", value: "gpt-4o" },
        { name: "gpt-4o-mini", value: "gpt-4o-mini" },
    ];

    onMount(async () => {
        const resp = await fetchFiles("research");

        files_list.update((prev) => {
            prev["research"] = resp;
            return prev;
        });
    });

    let loading_baseline = false;

    let loading_internet = false;
    let loading_general = false;

    async function handleSubmit() {
        loading_baseline = true;
        if ($current_user?.user_type !== "admin") {
            selected_model.set("gemini-1.5-pro");
        }
        let baseline_resp = await ask_llm(
            $research_baseline_user_instructions,
            "research",
            "upload",
            "baseline",
            $selected_model,
        );
        generated_content.update((prev) => {
            prev["research"]["upload"]["baseline"]["generated_content"] =
                baseline_resp.generated_content;
            return prev;
        });
        toast.push("Baseline statements done");

        let internet_resp = await ask_llm(
            baseline_resp.generated_content +
                $research_baseline_user_instructions,
            "research",
            "upload",
            "internet",
            $selected_model,
            false
        );
        generated_content.update((prev) => {
            prev["research"]["upload"]["internet"]["generated_content"] =
                internet_resp.generated_content;
            return prev;
        });

        toast.push("Internet research done");

        loading_baseline = false;
    }

    async function handleAskInternet() {
        loading_internet = true;
        if ($current_user?.user_type !== "admin") {
            selected_model.set("perplexity");
        }

        let resp = await ask_llm(
            $generated_content["research"]["ask"]["internet"][
                "user_instructions"
            ],
            "research",
            "ask",
            "internet",
            $selected_model,
        );
        generated_content.update((prev) => {
            prev["research"]["ask"]["internet"]["generated_content"] =
                resp.generated_content;
            return prev;
        });
        loading_internet = false;
        toast.push("Internet search generated");
    }

    async function handleAskGeneral() {
        loading_general = true;
        if ($current_user?.user_type !== "admin") {
            selected_model.set("gpt-4o");
        }
        let resp = await ask_llm(
            $generated_content["research"]["ask"]["general"][
                "user_instructions"
            ],
            "research",
            "ask",
            "general",
            $selected_model,
        );
        generated_content.update((prev) => {
            prev["research"]["ask"]["general"]["generated_content"] =
                resp.generated_content;
            return prev;
        });
        loading_general = false;
        toast.push("General search generated");
    }

    async function handleDownload(type) {
        downloading_file = true;
        let payload = {};

        if ($research_page_selected_tab === "upload") {

            payload["baseline"] = $generated_content["research"]["upload"]["baseline"]["generated_content"];
            payload["internet"] = $generated_content["research"]["upload"]["internet"]["generated_content"];

         
        } else if ($research_page_selected_tab === "ask") {
            payload["internet"] = $generated_content["research"]["ask"]["internet"][
                "generated_content"
            ];
            payload["general"] = $generated_content["research"]["ask"]["general"][
                "generated_content"
            ];
        }
        let resp = await download_file(
            payload,
            type,
            "research_" + $research_page_selected_tab,
            "Baseline Research",
        );
        downloading_file = false;
    }

    $: isUploadTabOpen = $research_page_selected_tab === "upload";
    $: isAskTabOpen = $research_page_selected_tab === "ask";
    $: submit_disabled = $files_list["research"].length == 0 || $files_list["research"].some(file => file.status !== "Ready");
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <Tabs contentClass="bg-white">
                <TabItem
                    bind:open={isUploadTabOpen}
                    title="File Upload"
                    on:click={() => {
                        research_page_selected_tab.set("upload");
                    }}
                >
                    <FilesTable module="research"></FilesTable>
                    <div class="flex justify-end my-5 items-center">
                        {#if $current_user?.user_type === "admin"}
                            <div class="w-48 mr-4">
                                <Select
                                    size="sm"
                                    items={all_models}
                                    bind:value={$selected_model}
                                    class="w-full"
                                />
                            </div>
                        {/if}
                        {#if loading_baseline}
                            <div class="flex items-center">
                                <Spinner></Spinner>
                                <small class="ml-2">Generating Baseline</small>
                            </div>
                        {:else}
                            <Button
                                size="sm"
                                color="purple"
                                disabled={submit_disabled}
                                on:click={handleSubmit}>Submit</Button
                            >
                        {/if}
                    </div>
                    <P size="lg" class="my-5"
                        ><Span gradient>Baseline Research</Span></P
                    >

                    <Accordion
                        multiple
                        activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                        inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                    >
                        <ResponseComponent
                            title="Baseline Statements"
                            formattedContent={$generated_content["research"][
                                "upload"
                            ]["baseline"]["generated_content"]}
                            module="research"
                            section="upload"
                            sub_section="baseline"
                        ></ResponseComponent>

                        <ResponseComponent
                            title="Internet Search"
                            formattedContent={$generated_content["research"][
                                "upload"
                            ]["internet"]["generated_content"]}
                            module="research"
                            section="upload"
                            sub_section="internet"
                            injected_extra_prompt={$generated_content[
                                "research"
                            ]["upload"]["baseline"]["generated_content"]}
                        ></ResponseComponent>
                    </Accordion>
                </TabItem>
                <TabItem
                    bind:open={isAskTabOpen}
                    title="Ask Your Own"
                    on:click={() => {
                        research_page_selected_tab.set("ask");
                    }}
                >
                    <P size="lg" class="my-5"
                        ><Span gradient>Baseline Research</Span></P
                    >

                    <Accordion
                        multiple
                        activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                        inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                    >
                        <AccordionItem>
                            <span slot="header">Ask Internet</span>
                            <Textarea
                                rows="5"
                                bind:value={$generated_content["research"][
                                    "ask"
                                ]["internet"]["user_instructions"]}
                            ></Textarea>
                            <div class="flex justify-end my-5 items-center5">
                                {#if $current_user?.user_type === "admin"}
                                    <div class="w-48 mr-4">
                                        <Select
                                            size="sm"
                                            items={all_models}
                                            bind:value={$selected_model}
                                            class="w-full"
                                        />
                                    </div>
                                {/if}
                                {#if loading_internet}
                                    <Spinner></Spinner>
                                {:else}
                                    <Button
                                        color="purple"
                                        on:click={handleAskInternet}
                                        >Submit</Button
                                    >
                                {/if}
                            </div>
                            <div class="max-w-full prose">
                                {#if $generated_content["research"]["ask"]["internet"]["generated_content"]}
                                    {@html marked(
                                        $generated_content["research"]["ask"][
                                            "internet"
                                        ]["generated_content"],
                                    )}
                                {:else}
                                    <P>No content generated</P>
                                {/if}
                            </div>
                            <div class="flex justify-between mt-5">
                                <div class="flex justify-start">
                                    <button
                                        on:click={() => {
                                            if (
                                                navigator &&
                                                navigator.clipboard
                                            ) {
                                                navigator.clipboard.writeText(
                                                    $generated_content[
                                                        "research"
                                                    ]["ask"]["internet"][
                                                        "generated_content"
                                                    ],
                                                );
                                            } else {
                                                console.error(
                                                    "Clipboard API not available",
                                                );
                                            }
                                            toast.push("Copied to clipboard!");
                                        }}
                                        style="background: none; border: none; cursor: pointer;"
                                        class="mr-5"
                                    >
                                        <FileCopyOutline></FileCopyOutline>
                                    </button>
                                    <button
                                        on:click={() => {
                                            $generated_content["research"][
                                                "ask"
                                            ]["internet"]["generated_content"] =
                                                "";
                                        }}
                                    >
                                        <TrashBinOutline></TrashBinOutline>
                                    </button>
                                </div>
                            </div>
                        </AccordionItem>

                        <AccordionItem>
                            <span slot="header">Ask general</span>
                            <Textarea
                                rows="5"
                                bind:value={$generated_content["research"][
                                    "ask"
                                ]["general"]["user_instructions"]}
                            ></Textarea>
                            <div class="flex justify-end my-5 items-center">
                                {#if $current_user?.user_type === "admin"}
                                    <div class="w-48 mr-4">
                                        <Select
                                            size="sm"
                                            items={all_models}
                                            bind:value={$selected_model}
                                            class="w-full"
                                        />
                                    </div>
                                {/if}
                                {#if loading_general}
                                    <Spinner></Spinner>
                                {:else}
                                    <Button
                                        color="purple"
                                        on:click={handleAskGeneral}
                                        >Submit</Button
                                    >
                                {/if}
                            </div>
                            <div class="max-w-full prose">
                                {#if $generated_content["research"]["ask"]["general"]["generated_content"]}
                                    {@html marked(
                                        $generated_content["research"]["ask"][
                                            "general"
                                        ]["generated_content"],
                                    )}
                                {:else}
                                    <P>No content generated</P>
                                {/if}
                            </div>
                            <div class="flex justify-between mt-5">
                                <div class="flex justify-start">
                                    <button
                                        on:click={() => {
                                            if (
                                                navigator &&
                                                navigator.clipboard
                                            ) {
                                                navigator.clipboard.writeText(
                                                    $generated_content[
                                                        "research"
                                                    ]["ask"]["general"][
                                                        "generated_content"
                                                    ],
                                                );
                                            } else {
                                                console.error(
                                                    "Clipboard API not available",
                                                );
                                            }
                                            toast.push("Copied to clipboard!");
                                        }}
                                        style="background: none; border: none; cursor: pointer;"
                                        class="mr-5"
                                    >
                                        <FileCopyOutline></FileCopyOutline>
                                    </button>
                                    <button
                                        on:click={() => {
                                            $generated_content["research"][
                                                "ask"
                                            ]["general"]["generated_content"] =
                                                "";
                                        }}
                                    >
                                        <TrashBinOutline></TrashBinOutline>
                                    </button>
                                </div>
                            </div>
                        </AccordionItem>
                    </Accordion>
                </TabItem>
            </Tabs>

            <div class="flex justify-end my-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >

                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text =
                                    $generated_content["research"]["upload"][
                                        "baseline"
                                    ]["generated_content"] +
                                    "\n\n" +
                                    $generated_content["research"]["upload"][
                                        "internet"
                                    ]["generated_content"];
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >While AI systems strive for accuracy, occasional errors may
                    occur. We recommend reviewing the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>

<Modal
    title="Add Instructions"
    bind:open={$research_add_instructions_modal_open}
    autoclose
>
    <Textarea rows="5" bind:value={$research_baseline_user_instructions}
    ></Textarea>
</Modal>
<Modal
    title="Clear Generated Responses & Files"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all the generated responses and files?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone. All generated content will be removed.
    </p>
    <svelte:fragment slot="footer">
        <Button
            color="red"
            on:click={async () => {
                console.log("clearing responses");
                generated_content.set(research_initial);
                await deleteAllFiles("research");
                files_list.update((prev) => {
                    prev["research"] = [];
                    return prev;
                });
                toast.push("Responses and files cleared");
            }}>Clear Responses</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>
