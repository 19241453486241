<script>
    import { toast } from "@zerodevx/svelte-toast";
    import {
        Accordion,
        Button,
        Card,
        Dropdown,
        DropdownItem,
        Modal,
        P,
        Span,
        Spinner,
        Textarea
    } from "flowbite-svelte";
    import {
        ChevronDownOutline
    } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import {
        async_ask_llm,
        download_file,
        fetchFiles,
    } from "../service/http_calls";
    import {
        files_list,
        loading_review,
        review,
        review_inital,
        review_instructions_modal,
        selected_section,
        selected_sub_section
    } from "../service/store.js";
    import SingleReviewItem from "./SingleReviewItem.svelte";

    onMount(async () => {
        const resp = await fetchFiles("review");

        files_list.update((prev) => {
            prev["review"] = resp;
            return prev;
        });
    });

    let clearResponsesModal = false;
    let downloading_file = false;

    const submitReview = async () => {
        loading_review.set(true);

        console.log("submitReview");
        // handling coherence
        const resp_coherence = await async_ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "coherence",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["overall"]["coherence"]["result_id"] =
                resp_coherence.result_id;
            return prev;
        });

        // handling risk factors
        const resp_risk_factors = await async_ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "risk_factors",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["overall"]["risk_factors"]["result_id"] =
                resp_risk_factors.result_id;
            return prev;
        });
        // handling research - baseline
        const resp_research = await async_ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline_research",
            "baseline",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["baseline_research"]["baseline"]["result_id"] =
                resp_research.result_id;
            return prev;
        });
        // handling research - internet
        const resp_research_internet = await async_ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline_research",
            "internet",
            "gemini-1.5-flash",
            [$review.baseline_research.baseline?.result_id?.toString()],
        );
        review.update((prev) => {
            prev["baseline_research"]["internet"]["result_id"] =
                resp_research_internet.result_id;
            return prev;
        });
        // handling research - baseline research
        const resp_research_baseline = await async_ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline_research",
            "baseline_research",
            "gpt-4o",
            [
                $review.baseline_research.baseline?.result_id?.toString(),
                $review.baseline_research.internet?.result_id?.toString(),
            ],
        );
        review.update((prev) => {
            prev["baseline_research"]["baseline_research"]["result_id"] =
                resp_research_baseline.result_id;
            return prev;
        });

        // handle baseline
        //handle comprehensiveness
        const resp_comprehensiveness = await async_ask_llm(
            $review.user_instructions.baseline,
            "review",
            "baseline",
            "comprehensiveness",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["baseline"]["comprehensiveness"]["result_id"] =
                resp_comprehensiveness.result_id;
            return prev;
        });
        // handle focus
        const resp_focus = await async_ask_llm(
            $review.user_instructions.baseline,
            "review",
            "baseline",
            "focus",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["baseline"]["focus"]["result_id"] = resp_focus.result_id;
            return prev;
        });
        // handle phrasing
        const resp_phrasing = await async_ask_llm(
            $review.user_instructions.baseline,
            "review",
            "baseline",
            "phrasing",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["baseline"]["phrasing"]["result_id"] = resp_phrasing.result_id;
            return prev;
        });
        // handle grammar
        const resp_grammar = await async_ask_llm(
            $review.user_instructions.baseline,
            "review",
            "baseline",
            "grammar",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["baseline"]["grammar"]["result_id"] = resp_grammar.result_id;
            return prev;
        });

        // handle advance
        // handle comprehensiveness
        const resp_advance_comprehensiveness = await async_ask_llm(
            $review.user_instructions.advance,
            "review",
            "advance",
            "comprehensiveness",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["advance"]["comprehensiveness"]["result_id"] =
                resp_advance_comprehensiveness.result_id;
            return prev;
        });
        // handle focus
        const resp_advance_focus = await async_ask_llm(
            $review.user_instructions.advance,
            "review",
            "advance",
            "focus",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["advance"]["focus"]["result_id"] =
                resp_advance_focus.result_id;
            return prev;
        });
        // handle phrasing
        const resp_advance_phrasing = await async_ask_llm(
            $review.user_instructions.advance,
            "review",
            "advance",
            "phrasing",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["advance"]["phrasing"]["result_id"] =
                resp_advance_phrasing.result_id;
            return prev;
        });
        // handle grammar
        const resp_advance_grammar = await async_ask_llm(
            $review.user_instructions.advance,
            "review",
            "advance",
            "grammar",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["advance"]["grammar"]["result_id"] =
                resp_advance_grammar.result_id;
            return prev;
        });

        // handle uncertainty
        // handle comprehensiveness
        const resp_uncertainty_comprehensiveness = await async_ask_llm(
            $review.user_instructions.uncertainty,
            "review",
            "uncertainty",
            "comprehensiveness",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["uncertainty"]["comprehensiveness"]["result_id"] =
                resp_uncertainty_comprehensiveness.result_id;
            return prev;
        });
        // handle focus
        const resp_uncertainty_focus = await async_ask_llm(
            $review.user_instructions.uncertainty,
            "review",
            "uncertainty",
            "focus",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["uncertainty"]["focus"]["result_id"] =
                resp_uncertainty_focus.result_id;
            return prev;
        });
        // handle phrasing
        const resp_uncertainty_phrasing = await async_ask_llm(
            $review.user_instructions.uncertainty,
            "review",
            "uncertainty",
            "phrasing",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["uncertainty"]["phrasing"]["result_id"] =
                resp_uncertainty_phrasing.result_id;
            return prev;
        });
        // handle grammar
        const resp_uncertainty_grammar = await async_ask_llm(
            $review.user_instructions.uncertainty,
            "review",
            "uncertainty",
            "grammar",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["uncertainty"]["grammar"]["result_id"] =
                resp_uncertainty_grammar.result_id;
            return prev;
        });

        // handle resolution
        // handle comprehensiveness
        const resp_resolution_comprehensiveness = await async_ask_llm(
            $review.user_instructions.resolution,
            "review",
            "resolution",
            "comprehensiveness",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["resolution"]["comprehensiveness"]["result_id"] =
                resp_resolution_comprehensiveness.result_id;
            return prev;
        });
        // handle focus
        const resp_resolution_focus = await async_ask_llm(
            $review.user_instructions.resolution,
            "review",
            "resolution",
            "focus",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["resolution"]["focus"]["result_id"] =
                resp_resolution_focus.result_id;
            return prev;
        });
        // handle phrasing
        const resp_resolution_phrasing = await async_ask_llm(
            $review.user_instructions.resolution,
            "review",
            "resolution",
            "phrasing",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["resolution"]["phrasing"]["result_id"] =
                resp_resolution_phrasing.result_id;
            return prev;
        });
        // handle grammar
        const resp_resolution_grammar = await async_ask_llm(
            $review.user_instructions.resolution,
            "review",
            "resolution",
            "grammar",
            "gpt-4o",
            [],
        );
        review.update((prev) => {
            prev["resolution"]["grammar"]["result_id"] =
                resp_resolution_grammar.result_id;
            return prev;
        });
        toast.push("All requests submitted");
    };
    async function handleDownload(doc_type) {
        downloading_file = true;
        let payload = {};
        Object.entries($review).forEach(([key, value]) => {
            Object.entries($review[key]).forEach(([key2, value2]) => {
                payload[`${key}_${key2}`] = value2.response;
            });
        });
        await download_file(payload, doc_type, "review", "Review Report");
        downloading_file = false;
    }

    $: submit_enabled =
        $review.user_instructions.baseline &&
        $review.user_instructions.advance &&
        $review.user_instructions.uncertainty &&
        $review.user_instructions.resolution;
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="mb-20">
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>
            <Textarea
                bind:value={$review.user_instructions.baseline}
                rows={10}
                placeholder="Enter Technical Narrative Draft Here..."
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
            <Textarea
                bind:value={$review.user_instructions.advance}
                rows={10}
                placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Textarea
                bind:value={$review.user_instructions.uncertainty}
                rows={10}
                placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Textarea
                bind:value={$review.user_instructions.resolution}
                rows={10}
                placeholder=""
            ></Textarea>
            <div class="flex justify-between items-center mt-5">
                <div class="flex-grow"></div>
                {#if $loading_review}
                    <div class="flex items-center">
                        <Spinner class="mr-5"></Spinner>
                        <P>Processing...</P>
                    </div>
                {:else}
                    <Button
                        color="purple"
                        on:click={() => submitReview()}
                        disabled={!submit_enabled}>Submit</Button
                    >
                {/if}
            </div>

            <P size="lg" class="my-5"><Span gradient>Overall</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem section="overall" sub_section="coherence" />
                <SingleReviewItem
                    section="overall"
                    sub_section="risk_factors"
                />
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Research</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem
                    section="baseline_research"
                    sub_section="baseline"
                />
                <SingleReviewItem
                    section="baseline_research"
                    sub_section="internet"
                />
                <SingleReviewItem
                    section="baseline_research"
                    sub_section="baseline_research"
                />
            </Accordion>
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem
                    section="baseline"
                    sub_section="comprehensiveness"
                />
                <SingleReviewItem section="baseline" sub_section="focus" />
                <SingleReviewItem section="baseline" sub_section="phrasing" />
                <SingleReviewItem section="baseline" sub_section="grammar" />
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
            <!-- add accordiosn for comprehensiveness, focus, phrasing, grammar here -->
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem
                    section="advance"
                    sub_section="comprehensiveness"
                />
                <SingleReviewItem section="advance" sub_section="focus" />
                <SingleReviewItem section="advance" sub_section="phrasing" />
                <SingleReviewItem section="advance" sub_section="grammar" />
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem
                    section="uncertainty"
                    sub_section="comprehensiveness"
                />
                <SingleReviewItem section="uncertainty" sub_section="focus" />
                <SingleReviewItem
                    section="uncertainty"
                    sub_section="phrasing"
                />
                <SingleReviewItem section="uncertainty" sub_section="grammar" />
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <SingleReviewItem
                    section="resolution"
                    sub_section="comprehensiveness"
                />
                <SingleReviewItem section="resolution" sub_section="focus" />
                <SingleReviewItem section="resolution" sub_section="phrasing" />
                <SingleReviewItem section="resolution" sub_section="grammar" />
            </Accordion>

            <div class="flex justify-end mt-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >
                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = "";
                                // Overall section
                                if ($review.overall.coherence.response) {
                                    text += `Overall - Coherence\n${$review.overall.coherence.response}\n\n`;
                                }
                                if ($review.overall.risk_factors.response) {
                                    text += `Overall - Risk Factors\n${$review.overall.risk_factors.response}\n\n`;
                                }
                                // Research section
                                if ($review.baseline_research.baseline.response) {
                                    text += `Research - Baseline\n${$review.baseline_research.baseline.response}\n\n`;
                                }
                                if ($review.baseline_research.internet.response) {
                                    text += `Research - Internet\n${$review.baseline_research.internet.response}\n\n`;
                                }
                                if (
                                    $review.baseline_research.baseline_research
                                        .response
                                ) {
                                    text += `Research - Baseline Research\n${$review.baseline_research.baseline_research.response}\n\n`;
                                }
                                // Advance section
                                if (
                                    $review.advance.comprehensiveness.response
                                ) {
                                    text += `Advance - Comprehensiveness\n${$review.advance.comprehensiveness.response}\n\n`;
                                }
                                if ($review.advance.focus.response) {
                                    text += `Advance - Focus\n${$review.advance.focus.response}\n\n`;
                                }
                                if ($review.advance.phrasing.response) {
                                    text += `Advance - Phrasing\n${$review.advance.phrasing.response}\n\n`;
                                }
                                if ($review.advance.grammar.response) {
                                    text += `Advance - Grammar\n${$review.advance.grammar.response}\n\n`;
                                }
                                // Uncertainty section
                                if (
                                    $review.uncertainty.comprehensiveness
                                        .response
                                ) {
                                    text += `Uncertainty - Comprehensiveness\n${$review.uncertainty.comprehensiveness.response}\n\n`;
                                }
                                if ($review.uncertainty.focus.response) {
                                    text += `Uncertainty - Focus\n${$review.uncertainty.focus.response}\n\n`;
                                }
                                if ($review.uncertainty.phrasing.response) {
                                    text += `Uncertainty - Phrasing\n${$review.uncertainty.phrasing.response}\n\n`;
                                }
                                if ($review.uncertainty.grammar.response) {
                                    text += `Uncertainty - Grammar\n${$review.uncertainty.grammar.response}\n\n`;
                                }
                                // Resolution section
                                if (
                                    $review.resolution.comprehensiveness
                                        .response
                                ) {
                                    text += `Resolution - Comprehensiveness\n${$review.resolution.comprehensiveness.response}\n\n`;
                                }
                                if ($review.resolution.focus.response) {
                                    text += `Resolution - Focus\n${$review.resolution.focus.response}\n\n`;
                                }
                                if ($review.resolution.phrasing.response) {
                                    text += `Resolution - Phrasing\n${$review.resolution.phrasing.response}\n\n`;
                                }
                                if ($review.resolution.grammar.response) {
                                    text += `Resolution - Grammar\n${$review.resolution.grammar.response}\n\n`;
                                }
                                text = text.trim();
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >While AI systems strive for accuracy, occasional errors may
                    occur. We recommend reviewing the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
<Modal
    title="Clear Generated Responses"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all the generated responses for the
        questions?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone. All generated content will be removed.
    </p>
    <svelte:fragment slot="footer">
        <Button
            color="red"
            on:click={() => {
                console.log("clearing responses");
                review.update((prev) => {
                    return review_inital;
                });
                toast.push("Responses cleared");
            }}>Clear Responses</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>

<Modal
    title="Add Instructions"
    bind:open={$review_instructions_modal}
    autoclose
>
    <Textarea
        rows="5"
        bind:value={$review[$selected_section][$selected_sub_section]
            .instructions}
    ></Textarea>
</Modal>
