<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Textarea
    } from "flowbite-svelte";
    import { onMount } from "svelte";
    import {
        fetchFiles,
    } from "../service/http_calls";
    import {
        files_list
    } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    let loading_ingest = false;


    onMount(async () => {
        const resp = await fetchFiles("defend");

        files_list.update( (prev) => {
            if (Array.isArray(resp)){
                prev["defend"] = resp;
            }
            return prev;
        });
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="w-full mb-40">
            <FilesTable module="defend"></FilesTable>
            <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold">Please provide instructions here.</P>
            <Textarea rows="5" class="mb-3"></Textarea>
            <div class="flex justify-end my-5">
                <Button size="sm" color="purple" class="hover:bg-purple-700 transition-colors duration-200">Submit</Button>
            </div>


            <P size="lg" class="my-5 font-bold"><Span gradient>Advance</Span></P>

            <P class="my-5 text-gray-700 dark:text-gray-300">An explanation of what the scientific or technological advance(s) is.</P>

            <P class="my-5 font-semibold">Your response must include:</P>

            <ul class="list-disc list-inside mb-5 space-y-2 text-gray-700 dark:text-gray-300">
                <li>What the gap in technological knowledge or capability was which necessitated the commencement of the R&D.</li>
                <li>The baseline in the science or technology that the advance was being measured against.</li>
                <li>What scientific or technological advance is being sought or achieved during the development of the project and not just your company's knowledge or capability alone.</li>
                <li>How this R&D project is new, or an appreciable improvement to the field of technology relative to what is available in the public domain and not readily deducible by a competent professional.</li>
                <li>If any patents were filed against the advance in the field of science or technology. If none were filed, please explain why you decided not to protect your intellectual property.</li>
            </ul>

            <P class="font-semibold my-5">What the gap in technological knowledge or capability was which necessitated the commencement of the R&D</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">The baseline in the science or technology that the advance was being measured against</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">What scientific or technological advance is being sought or achieved during the development of the project and not just your company's knowledge or capability alone</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">How this R&D project is new, or an appreciable improvement to the field of technology relative to what is available in the public domain and not readily deducible by a competent professional</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">If any patents were filed against the advance in the field of science or technology. If none were filed, please explain why you decided not to protect your intellectual property</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5 font-bold"><Span gradient>Uncertainty</Span></P>

            <P class="my-5 text-gray-700 dark:text-gray-300">An explanation of what the scientific or technological uncertainties involved in the project were.</P>

            <P class="my-5 text-gray-700 dark:text-gray-300">Please provide an explanation of the research undertaken to establish these were uncertainties in the overall field of technology and can't be solved by readily deducible methods within your company's knowledge or by information available in the public domain. Bear in mind that just because your company does not know if something could be done, does not make it an uncertainty.</P>

            <P class="my-5 font-semibold">Your response must include an explanation, in a form understandable to the non-expert, of what these uncertainties were, stating:</P>

            <ul class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300">
                <li>The current technological limitations</li>
                <li>Current practice and why it fails to resolve the uncertainty</li>
                <li>An explanation of the research undertaken to establish these were uncertainties in the overall field of technology that could not be solved by the competent professional</li>
                <li>Whether the methods used to solve the uncertainties were not already established in the public domain</li>
            </ul>

            <P class="font-semibold my-5">The current technological limitations</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">Current practice and why it fails to resolve the uncertainty</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">An explanation of the research undertaken to establish these were uncertainties in the overall field of technology that could not be solved by the competent professional</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">Whether the methods used to solve the uncertainties were not already established in the public domain</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5 font-bold"><Span gradient>Resolution</Span></P>

            <P class="my-5 text-gray-700 dark:text-gray-300">Detail of how and when the uncertainties were overcome.</P>

            <P class="my-5 text-gray-700 dark:text-gray-300">Please provide us with the specific methodology undertaken to overcome the uncertainties outlined in your response and how these methods were not readily deducible by a competent professional.</P>

            <P class="my-5 text-gray-700 dark:text-gray-300">Please also provide a detailed timeline for your R&D project. This must include all stages from planning to deployment of the R&D outcome, what individuals were involved at each stage, and any related documents you hold that were produced throughout each stage.</P>

            <P class="my-5 text-gray-700 dark:text-gray-300">If the R&D is still underway, please just cover the timeline up until the current position of the project and give an explanation as to why the project is still in progress. In the timeline, you should include details of:</P>

            <ul class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300">
                <li>How and when the uncertainties were identified</li>
                <li>How and when the uncertainties were overcome</li>
                <li>All stages from planning to deployment</li>
                <li>What individuals were involved at each stage</li>
                <li>Any related contemporaneous project documents that informed and support the R&D relief claim</li>
            </ul>

            <P class="font-semibold my-5">How and when the uncertainties were identified</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">How and when the uncertainties were overcome</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">What individuals were involved at each stage</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P class="font-semibold my-5">Any related contemporaneous project documents that informed and support the R&D relief claim</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5 font-bold"><Span gradient>Not Readily Deducible</Span></P>

            <P class="my-5 text-gray-700 dark:text-gray-300">Detail as to why the knowledge being sought was not readily deducible by a competent professional.</P>

            <P class="my-5 text-gray-700 dark:text-gray-300">You'll need to show that the persons leading the R&D project are themselves competent professionals working in the relevant field. This might be done by outlining their relevant background, professional qualifications, and recent experience. Then have them explain why they consider the uncertainties as scientific or technological uncertainties rather than routine uncertainties.</P>

            <P class="font-semibold my-5">Not readily deducible</P>

            <Accordion multiple activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800" inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800">
                <AccordionItem>
                    <span slot="header" class="font-medium">Outline</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="font-medium">Draft</span>
                    <p class="text-gray-600 dark:text-gray-400">No Content Generated</p>
                </AccordionItem>
            </Accordion>

        </Card>
    </div>
</div>
